<template>
    <div class="cell-v3">
        <div class="icon" v-if="!hideIcon">
            <slot name="icon"></slot>
        </div>
        <div class="title">
            <slot name="title"></slot>
        </div>
        <div class="actions" v-if="!hideActions">
            <slot></slot>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'cellV3',
    props: {
      hideIcon: {
        type: Boolean,
        default: false
      },
      hideTitle: {
        type: Boolean,
        default: false
      },
      hideActions: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped lang="scss">
    .cell-v3 {
        width: 100%;
        display: flex;

        .icon {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 13px;
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 85px;
        }

        .actions {
            flex: 1;
            /*min-width: 60px;*/
            display: flex;
            align-items: center;
            justify-content: flex-end;
            /*overflow-y:scroll;*/

        }

    }
</style>